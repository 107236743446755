import { sessionStorageKeys } from '../constants';
import { useLocalStorage } from './useLocalStorage';

export const packageManagerMap = {
  npm: { packagePath: 'npm i' },
  yarn: { packagePath: 'yarn add' },
};

export const hasWagmiMap = {
  true: {
    packagePath: 'wagmi @tanstack/react-query @dynamic-labs/wagmi-connector',
  },
  false: { packagePath: '' },
};

export const clientMap = {
  viem: { packagePath: '' },
  ethers: { packagePath: '@dynamic-labs/ethers-v6' },
};

export const connectorsMap = {
  Ethereum: {
    packagePath: '@dynamic-labs/ethereum',
    connector: 'EthereumWalletConnectors',
  },
  Solana: {
    packagePath: '@dynamic-labs/solana',
    connector: 'SolanaWalletConnectors',
  },
  Bitcoin: {
    packagePath: '@dynamic-labs/bitcoin',
    connector: 'BitcoinWalletConnectors',
  },
  Eclipse: {
    packagePath: '@dynamic-labs/eclipse',
    connector: 'EclipseWalletConnectors',
  },
  Algorand: {
    packagePath: '@dynamic-labs/algorand',
    connector: 'AlgorandWalletConnectors',
  },
  Flow: {
    packagePath: '@dynamic-labs/flow',
    connector: 'FlowWalletConnectors',
  },
  Starknet: {
    packagePath: '@dynamic-labs/starknet',
    connector: 'StarknetWalletConnectors',
  },
  Cosmos: {
    packagePath: '@dynamic-labs/cosmos',
    connector: 'CosmosWalletConnectors',
  },
};

export const useProjectSetupVariables = () => {
  const [packageManager, setPackageManager] = useLocalStorage(
    'npm',
    sessionStorageKeys.packageManager
  );

  const [client, setClient] = useLocalStorage(
    'viem',
    sessionStorageKeys.client
  );

  const [hasWagmi, setHasWagmi] = useLocalStorage(
    false,
    sessionStorageKeys.hasWagmi
  );

  const [connectors, setConnectors] = useLocalStorage(
    ['Ethereum'],
    sessionStorageKeys.chains
  );

  return {
    packageManager,
    setPackageManager,
    client,
    setClient,
    hasWagmi,
    setHasWagmi,
    connectors: connectors.sort(),
    setConnectors,
  };
};
