import { useMemo } from 'react';
import { connectorsMap, useProjectSetupVariables } from '../../utils';

const highlightStrings = [
  '<DynamicWagmiConnector>',
  '</DynamicWagmiConnector>',
  'DynamicWagmiConnector',
  'EthersExtension',
  ...Object.values(connectorsMap).map(({ connector }) => connector),
];

const includeIf = (condition, content) => (condition ? content : '');

export const useQuickstartCode = () => {
  const { connectors, hasWagmi } = useProjectSetupVariables();

  const walletConnectors = useMemo(() => {
    if (connectors.length === 0) return '';

    if (connectors.length === 1)
      return `
        walletConnectors: [${connectorsMap[connectors[0]].connector}],`;

    return `
        walletConnectors: [${connectors.map(
          (connector) => `
          ${connectorsMap[connector].connector}`
        )}
        ],`;
  }, [connectors]);

  const output = `\
import {
  DynamicContextProvider,
  DynamicWidget,
} from "@dynamic-labs/sdk-react-core";\

${includeIf(
  hasWagmi,
  `\
import { DynamicWagmiConnector } from "@dynamic-labs/wagmi-connector";
import {
  createConfig,
  WagmiProvider,
} from 'wagmi';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { http } from 'viem';
import { mainnet } from 'viem/chains';
`
)}\
${connectors
  .map(
    (connectorName, index) => `${index === 0 ? '\n' : ''}\
import { ${connectorsMap[connectorName].connector} } from "${
      connectorsMap[connectorName].packagePath
    }";
`
  )
  .join('')}\

${includeIf(
  hasWagmi,
  `\
const config = createConfig({
  chains: [mainnet],
  multiInjectedProviderDiscovery: false,
  transports: {
    [mainnet.id]: http(),
  },
});
  
const queryClient = new QueryClient();
  `
)}\

export default function App() {
  return (
    <DynamicContextProvider
      settings={{
        // Find your environment id at https://app.dynamic.xyz/dashboard/developer
        environmentId: "REPLACE-WITH-YOUR-ENVIRONMENT-ID",
        ${walletConnectors}
      }}
    >
${
  hasWagmi
    ? `\
      <WagmiProvider config={config}>
        <QueryClientProvider client={queryClient}>
          <DynamicWagmiConnector>
            <DynamicWidget />
          </DynamicWagmiConnector>
        </QueryClientProvider>
      </WagmiProvider> `
    : `\
      <DynamicWidget />`
}
    </DynamicContextProvider>
  );
};\
`;

  return { output, highlightStrings };
};
