import {
  useDynamicContext,
  useOtpVerificationRequest,
  useUserUpdateRequest,
} from '@dynamic-labs/sdk-react-core';
import { useState } from 'react';

export const UpdateUserView = () => {
  const [loading, setLoading] = useState(false);
  const [showVerifyEmailForm, setShowVerifyEmailForm] = useState(false);

  // Get the current user's information from the DynamicContext
  const { user } = useDynamicContext();
  // Get the updateUser function from the useUserUpdateRequest hook
  const { updateUser } = useUserUpdateRequest();
  // Get the verifyEmail function from the useEmailVerificationRequest hook
  const { verifyOtp } = useOtpVerificationRequest();

  // Handler for the profile update form submission
  const onProfileFormSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      // Call the updateUser function with the new values entered by the user
      const { isEmailVerificationRequired } = await updateUser({
        firstName: e.target[0].value,
        email: e.target[1].value,
      });
      // If email verification is required, show the email verification form
      if (isEmailVerificationRequired) {
        setShowVerifyEmailForm(true);
      }
    } catch (e) {
      console.log('Error', e);
    } finally {
      setLoading(false);
    }
    return false;
  };

  // Handler for the email verification form submission
  const onVerifyEmailFormSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const verificationToken = e.target[0].value;
      // Call the verifyEmail function with the entered verification token
      await verifyOtp(verificationToken, 'email');
    } catch (e) {
      console.log('Error', e);
    } finally {
      setLoading(false);
      // Hide the email verification form after the process is completed
      setShowVerifyEmailForm(false);
    }
    return false;
  };

  console.log('user', JSON.stringify(user, null, 2));

  return (
    <div className="form-container">
      <form onSubmit={onProfileFormSubmit} className="form">
        <p className="explanation">
          Uses custom-made UI for updates and email verification
        </p>

        {/* Render the profile update form */}
        <div className="form__rows">
          <div className="form__row">
            <label htmlFor="firstName">First Name</label>
            <input
              id="firstName"
              defaultValue={user.firstName}
              disabled={loading || showVerifyEmailForm}
            />
          </div>
          <div className="form__row">
            <label htmlFor="email">E-Mail</label>
            <input
              type="email"
              id="email"
              defaultValue={user.email}
              disabled={loading || showVerifyEmailForm}
            />
          </div>
        </div>
        <button
          disabled={loading || showVerifyEmailForm}
          className="form__button"
          type="submit"
        >
          Save
        </button>
      </form>

      {/* Render the email verification form if needed */}
      {showVerifyEmailForm && (
        <form
          onSubmit={onVerifyEmailFormSubmit}
          className="form email-verification"
        >
          <h1>Email Verification</h1>
          <div className="form__row">
            <input
              disabled={loading}
              pattern="^\d{6}$"
              name="verificationToken"
              className="no-label"
              placeholder="Verification token"
            />
          </div>
          <button disabled={loading} className="form__button" type="submit">
            Send
          </button>
        </form>
      )}
    </div>
  );
};
