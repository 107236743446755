import { useMemo } from 'react';

import { CopyBlock } from '../../components/CopyBlock';
import { ProjectSetupToggles } from '../../components/ProjectSetupToggles';
import {
  clientMap,
  connectorsMap,
  hasWagmiMap,
  packageManagerMap,
  useProjectSetupVariables,
} from '../../utils';

export function QuickstartCommand() {
  const { client, connectors, hasWagmi, packageManager } =
    useProjectSetupVariables();

  const output = useMemo(() => {
    return [
      packageManagerMap[packageManager].packagePath,
      'viem',
      hasWagmiMap[hasWagmi].packagePath,
      '@dynamic-labs/sdk-react-core',
      clientMap[client].packagePath,
      ...connectors.map((connector) => connectorsMap[connector].packagePath),
    ]
      .filter((command) => Boolean(command))
      .join(' ');
  }, [packageManager, client, hasWagmi, connectors]);

  return (
    <div className="quickstart-command">
      <ProjectSetupToggles />

      <CopyBlock
        output={output}
        settings={{ lineNumbers: false }}
        language="shell"
        highlightStrings={['npm i', 'yarn add']}
      />
    </div>
  );
}
