import { Link, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import {
  CssVariablesDemo,
  EmbeddedWidgetDemo,
  QuickstartCode,
  QuickstartCommand,
  TemplateEditor,
  UseUserUpdateRequest,
} from '..';
import { ApplyColorScheme } from './ApplyColorScheme';

const routes = {
  'css-variables': <CssVariablesDemo />,
  'embedded-widget': <EmbeddedWidgetDemo />,
  quickstart: <QuickstartCommand />,
  quickstartV2: <QuickstartCommand />,
  'quickstart-dynamic-context-provider': <QuickstartCode />,
  'quickstart-dynamic-context-providerV2': <QuickstartCode />,
  'use-user-update-request': <UseUserUpdateRequest />,
  'template-editor': <TemplateEditor />,
};

export const View = () => {
  return (
    <div className="view">
      <Router>
        <ApplyColorScheme />

        <Routes>
          {Object.entries(routes).map(([path, element]) => (
            <Route key={path} path={path} element={element} />
          ))}

          <Route
            path="*"
            element={
              <div className="route-index">
                <h1>Choose an embed</h1>

                {Object.keys(routes).map((path) => (
                  <Link key={path} to={path}>
                    {path}
                  </Link>
                ))}
              </div>
            }
          ></Route>
        </Routes>
      </Router>
    </div>
  );
};
